<template>
    <div>
        <b-row>
            <b-col cols="12" md="4">
                <div>
                    <b-col>
                        <statistic-card-vertical icon="UsersIcon" :statistic="dashboard.totalCustomer"
                            statistic-title="Customers" color="info" />
                    </b-col>
                    <b-col>
                        <statistic-card-vertical icon="SendIcon" :statistic="dashboard.smsSent" statistic-title="Sent Reminder"
                            color="info" />
                    </b-col>
                    <b-col>
                        <statistic-card-vertical icon="BarChart2Icon" :statistic="dashboard.avgSumInsured"
                            statistic-title="Avg. Sum Insured" color="info" />
                    </b-col>
                </div>
            </b-col>
            <b-col cols="12" md="8">
                <div>
                    <b-card no-body>
                        <b-card-header>
                            <b-card-title>Customer's Vehicle Type & Percentage</b-card-title>
                        </b-card-header>
                        <b-card-body>
                            <app-echart-doughnut style="width: 400px; min-width: 100%; height:400px;"
                                :series="percentageVehicleSeries" />
                        </b-card-body>
                    </b-card>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="6">
                <div class="">
                    <b-card no-body>
                        <b-card-header>
                            <b-card-title>NCD - Cars</b-card-title>
                        </b-card-header>
                        <b-card-body>
                            <app-echart-bar style="width: 400px; min-width: 100%; height:400px;" :option-data="ncdCarBar" />
                        </b-card-body>
                    </b-card>
                </div>
            </b-col>
            <b-col cols="12" md="6">
                <div class="">
                    <b-card no-body>
                        <b-card-header>
                            <b-card-title>NCD - Motorcycles</b-card-title>
                        </b-card-header>
                        <b-card-body>
                            <app-echart-bar style="width: 400px; min-width: 100%; height:400px;"
                                :option-data="ncdMotorcycleBar" />
                        </b-card-body>
                    </b-card>

                </div>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <b-card-title>Customer By State</b-card-title>
                    </b-card-header>
                    <b-card-body>
                        <b-card-group>
                            <div v-for="state in dashboard.customerByState">
                                <b-card :title="state.name">
                                    <b-card-text>{{ state.value }}</b-card-text>
                                </b-card>
                            </div>
                        </b-card-group>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <b-card-title>Top 10 Models</b-card-title>
                    </b-card-header>
                    <b-card-body>
                        <b-card-group>
                            <div v-for="car in dashboard.top10Models">
                                <b-card :title="car.model">
                                    <b-card-text>{{ car.count }}</b-card-text>
                                </b-card>
                            </div>
                        </b-card-group>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import AppEchartBar from '@/@core/components/charts/echart/AppEchartBar.vue';
import AppEchartDoughnut from '@/@core/components/charts/echart/AppEchartDoughnut.vue';
import StatisticCardVertical from '@/@core/components/statistics-cards/StatisticCardVertical.vue';
import { getAgentDashboard } from '@/services/AgentService';
import { BCard, BCardBody, BCardGroup, BCardHeader, BCardText, BCardTitle, BCol, BRow } from 'bootstrap-vue';

export default {
    components: {
        BCard,
        BCardTitle,
        BCardHeader,
        BCardBody,
        StatisticCardVertical,
        BCardText,
        BCardGroup,
        BRow,
        BCol,
        AppEchartDoughnut,
        AppEchartBar
    },
    data() {
        return {
            dashboard: {
                totalCustomer: 0,
                smsSent: 0,
                avgSumInsured: 0,
                ncdPercentage: {
                    MOTORCYCLE: {},
                    CAR: {},
                },
                percentageVehicle: {
                    MOTORCYCLE: 0,
                    CAR: 0,
                },
                customerByState: {},
            },
            ncdCarBar: {
                grid: {
                    left: "center",
                    right: "middle",
                    bottom: "0",
                    width: "100%",
                    height: "85%",
                    containLabel: true
                },
                xAxis: {
                    type: "category",
                    data: []
                },
                yAxis: {
                    type: "value",
                    splitLine: {
                        show: false
                    }
                },
                series: [
                    {
                        name: "Car NCD percentage and count",
                        type: "bar",
                        data: []
                    }
                ]
            },
            ncdMotorcycleBar: {
                grid: {
                    left: "center",
                    right: "middle",
                    bottom: "0",
                    width: "100%",
                    height: "85%",
                    containLabel: true
                },
                xAxis: {
                    type: "category",
                    data: []
                },
                yAxis: {
                    type: "value",
                    splitLine: {
                        show: false
                    }
                },
                series: [
                    {
                        name: "Motorcycle NCD percentage and count",
                        type: "bar",
                        data: []
                    }
                ]
            },
            percentageVehicleSeries: {
                name: "Vehicle",
                type: "pie",
                radius: ["50%", "70%"],
                avoidLabelOverlap: false,
                grid: {
                    width: "100%",
                    height: "100%",
                },
                label: {
                    show: false,
                    position: "center"
                },
                data: []
            }
        };
    },
    async mounted() {
        const dashboard = await getAgentDashboard();
        this.dashboard = dashboard;

        this.ncdCarBar.xAxis.data = Object.keys(dashboard.ncdPercentage.CAR).map(key => `${key}%`);
        this.ncdCarBar.series[0].data = Object.keys(
            dashboard.ncdPercentage.CAR
        ).map(key => dashboard.ncdPercentage.CAR[key]);

        this.ncdMotorcycleBar.xAxis.data = Object.keys(
            dashboard.ncdPercentage.MOTORCYCLE
        ).map(key => `${key}%`);
        this.ncdMotorcycleBar.series[0].data = Object.keys(
            dashboard.ncdPercentage.MOTORCYCLE
        ).map(key => dashboard.ncdPercentage.MOTORCYCLE[key]);

        this.percentageVehicleSeries.data = [
            {
                value: dashboard.percentageVehicle.CAR,
                name: "Car"
            },
            {
                value: dashboard.percentageVehicle.MOTORCYCLE,
                name: "Motorcycle"
            }
        ];
    }
}
</script>
<style></style>
